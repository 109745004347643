/** @jsx jsx */
import { Global } from '@emotion/react';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { jsx, useColorMode } from 'theme-ui';

import Chicken from '../assets/svg/chx';
import { Footer } from '../components/footer/Footer';
import Github from '../assets/svg/github';
import Home from '../assets/svg/home';
import Lightbulb from '../assets/svg/lightbulb';
import Linkedin from '../assets/svg/linkedin';
import Resume from '../assets/svg/resume';
import './AppLayout.scss';
import Link from '../components/link/Link';
import theme from '../gatsby-plugin-theme-ui';

const Button = styled.button`
  border-radius: 3px;
  background-color: transparent;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;

  border: 0;

  appearance: none;

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

interface AppLayoutProps {
  children: ReactNode;
}

const nextThemeMap = {
  light: 'dark',
  dark: 'disco',
  disco: 'light',
};

const toggleNextColor = (colorMode: string) => nextThemeMap[colorMode];

const getHomeSVG = (clrs) => Home(clrs.text);
const getGHSVG = (clrs) => {
  // eslint-disable-next-line no-console
  console.log('getting gh icon');
  // eslint-disable-next-line no-console
  console.log(clrs.text);
  return Github(clrs.text);
};
const getLISVG = (clrs) => Linkedin(clrs.text);
const getChickenSVG = (clrs) => {
  // eslint-disable-next-line no-console
  console.log('getting chx icon');
  // eslint-disable-next-line no-console
  console.log(clrs.chx);
  return Chicken(clrs.chx);
};
const getResumeSVG = (clrs) => Resume(clrs.text);

export default ({ children }: AppLayoutProps) => {
  // localStorage['theme-ui-color-mode'] = 'light';
  const [colorMode, setColorMode] = useColorMode();
  // eslint-disable-next-line no-console
  console.log(colorMode);

  let colorTheme =
    (theme.colors.modes && theme.colors.modes[colorMode]) ?? theme.colors;
  const toggleColorMode = () => {
    setColorMode(toggleNextColor(colorMode));
    colorTheme = theme.colors.modes[colorMode] ?? theme.colors;
  };

  const getLightSVG = (clrs) => (
    <Button onClick={toggleColorMode}>{Lightbulb(clrs.light)}</Button>
  );

  const iconColors = {
    text: colorTheme.text,
    chx: colorTheme.iconChx,
    light: colorTheme.iconLight,
  };

  const iconLinks = [
    { getIcon: getHomeSVG, key: 'home', to: '/', target: '' },
    {
      getIcon: getGHSVG,
      key: 'github',
      to: 'https://github.com/kamiboers',
      target: '_blank',
    },
    {
      getIcon: getLISVG,
      key: 'linkedin',
      to: 'https://www.linkedin.com/in/kamiboers',
      target: '_blank',
    },
    {
      getIcon: getResumeSVG,
      key: 'resume',
      to: 'https://kamwise.io/kboers-resume.pdf',
      target: '_blank',
    },
    { getIcon: getChickenSVG, key: 'chx', to: '/choops', target: '' },
    { getIcon: getLightSVG, key: 'light', to: '' },
  ];

  return (
    <React.Fragment>
      <Global
        styles={() => ({
          html: {
            fontSize: '18px',
            WebkitTextSizeAdjust: '100%',
          },
        })}
      />
      <main className="layout">
        {children}

        <Footer
          accentColor={colorTheme.line}
          footerLinks={iconLinks.map((link) =>
            link.to.length ? (
              <Link key={link.key} to={link.to} target={link.target}>
                {link.getIcon(iconColors)}
              </Link>
            ) : (
              <span>{link.getIcon(iconColors)}</span>
            ),
          )}
        />
      </main>
    </React.Fragment>
  );
};
