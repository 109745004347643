const theme = {
  config: {
    initialColorModeName: 'light',
    useColorSchemeMediaQuery: false,
  },
  colors: {
    background: '#f6f6f6',
    highlight: 'RGBA(124, 253, 214, .7)',
    icon: '#666666',
    iconAlt: '#000000',
    iconChx: '#8900c7',
    iconLight: '#FF2B00',
    line: '#e2e2e2',
    modes: {
      disco: {
        background: 'rgb(102, 0, 102)',
        text: '#ffbfff',
        highlight: 'RGBA(89, 33, 117, .8)',
        icon: '#ffbfff',
        iconAlt: 'rgb(255, 107, 255, .2)',
        iconChx: '#FF9418',
        iconLight: '#ffff02',
        line: 'rgb(255, 107, 255, .2)',
      },
      dark: {
        background: '#1a1a1a',
        text: '#29ffd8',
        highlight: 'RGBA(31, 60, 202, .2)',
        line: '#4fd9c0',
        icon: '#29ffd8',
        iconAlt: '#ffffff',
        iconChx: '#02ff39',
        iconLight: '#c8ff02',
      },
    },
  },
};

export default theme;
