import * as React from 'react';

export default (fillColor: string) => (
  <svg
    id="Layer_1"
    height="25"
    viewBox="35 35 550 575"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={fillColor}
        d="m254.054 38.465c-35.32.499-68.588 14.67-93.676 39.902-25.086 25.23-39.069 58.578-39.373 93.901-.307 35.749 13.229 69.556 38.114 95.193 20.559 21.181 31.881 48.555 31.881 77.079v70.912c0 35.841 29.159 65 65 65s65-29.159 65-65v-70.917c0-28.746 11.141-55.932 31.371-76.55 24.91-25.39 38.629-58.962 38.629-94.533 0-36.42-14.284-70.561-40.221-96.134-25.931-25.569-60.273-39.355-96.725-38.853zm-33.054 321.987h70v30.543h-70zm35 90c-17.412 0-31.891-12.783-34.556-29.457h69.113c-2.666 16.674-17.145 29.457-34.557 29.457zm74.957-203.478c-22.439 22.871-36.095 52.103-39.248 83.478h-71.435c-3.203-31.191-16.988-60.56-39.628-83.885-19.354-19.939-29.88-46.234-29.642-74.041.486-56.582 46.904-103.265 103.474-104.063.51-.007 1.02-.011 1.529-.011 27.78 0 53.904 10.7 73.71 30.228 20.173 19.89 31.283 46.445 31.283 74.772 0 27.665-10.669 53.776-30.043 73.522z"
      />
      <path fill={fillColor} d="m0 157.397h76.004v30h-76.004z" />
      <path
        fill={fillColor}
        d="m28.679 266.537h76.005v30h-76.005z"
        transform="matrix(.866 -.5 .5 .866 -131.836 71.06)"
      />
      <path
        fill={fillColor}
        d="m429.265 245.362h30v76.005h-30z"
        transform="matrix(.5 -.866 .866 .5 -23.269 526.425)"
      />
      <path fill={fillColor} d="m435.996 159.506h76.004v30h-76.004z" />
      <path
        fill={fillColor}
        d="m407.317 50.367h76.005v30h-76.005z"
        transform="matrix(.866 -.5 .5 .866 26.979 231.419)"
      />
      <path
        fill={fillColor}
        d="m52.736 25.537h30v76.004h-30z"
        transform="matrix(.5 -.866 .866 .5 -21.159 90.431)"
      />
    </g>
  </svg>
);
