/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx } from 'theme-ui';

import { Container } from '../container/Container';
import './Footer.scss';

interface FooterProps {
  accentColor: string;
  toggle?: ReactNode;
  footerLinks: any[]; // TODO
}

export const Footer = ({ toggle, footerLinks, accentColor }: FooterProps) => (
  <div className="footer">
    <Container>
      <div className="footer__content" sx={{ borderColor: accentColor }}>
        <span>{toggle}</span>
        <ul className="footer__list">
          {footerLinks.map((link) => (
            <li key={link.key} className="footer__item">
              {link}
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </div>
);
