import * as React from 'react';

export default (fillColor: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    height="20px"
    viewBox="0 10 512 512"
  >
    <g>
      <g>
        <path
          fill={fillColor}
          d="M469.333,182.857c-20.197,0-36.571-16.374-36.571-36.571c0-20.197,16.374-36.571,36.571-36.571
        c20.197,0,36.571-16.374,36.571-36.571c0-26.93-21.832-48.762-48.762-48.762c-20.197,0-36.571,16.374-36.571,36.571
        c0,20.197-16.374,36.571-36.571,36.571c-20.197,0-36.571-16.374-36.571-36.571C347.429,27.29,320.139,0,286.476,0
        s-60.952,27.29-60.952,60.952c0,13.723,4.537,26.384,12.189,36.571h36.573c94.106,0,170.667,76.561,170.667,170.667v20.269
        c5.543,2.633,11.741,4.112,18.286,4.112c30.476,0,42.667-24.381,42.667-73.143C505.905,199.231,489.531,182.857,469.333,182.857z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill={fillColor}
          d="M274.286,134.095h-85.333v231.619c0,80.791,65.495,146.286,146.286,146.286h73.143V268.19
        C408.381,194.132,348.344,134.095,274.286,134.095z M298.667,268.19h-0.002c-13.442,0-24.379-10.937-24.379-24.381
        c0.005-13.446,10.942-24.381,24.381-24.381c13.444,0,24.381,10.937,24.381,24.381C323.048,257.253,312.11,268.19,298.667,268.19z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill={fillColor}
          d="M91.429,304.762c-47.128,0-85.333,38.205-85.333,85.333c0,47.128,38.205,85.333,85.333,85.333
        c23.884,0,45.463-9.824,60.952-25.637v-145.03H91.429z"
        />
      </g>
    </g>
    <g>
      <g>
        <polygon
          fill={fillColor}
          points="6.095,134.095 152.381,280.381 152.381,134.095 		"
        />
      </g>
    </g>
  </svg>
);
